import { useNavigate, useLocation } from 'react-router-dom';
import Image from './Image';
import { useRef, useState } from 'react';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { XLg } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

function Slider() {
  const navigate = useNavigate();
  const location = useLocation();

  const imgName = location.state.name;
  const allImgs = useSelector((state) => state.categoryData.allImgs);
  const view = allImgs.findIndex((item) => item.name === imgName);

  const [currentIndex, setCurrentIndex] = useState(view);

  const nextImg = () => {
    if (currentIndex === allImgs.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((nextIndex) => nextIndex + 1);
    }
  };

  const prevImg = () => {
    if (currentIndex === 0) {
      setCurrentIndex(allImgs.length - 1);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const buttonContainerRef = useRef(null);

  const closeSlider = () => {
    navigate(-1);
  };

  return (
    <div id="sliderMainContainer" className="slider-main-container" ref={buttonContainerRef}>
      <button className="slider-close" onClick={closeSlider}>
        <XLg className="close-icon" />
      </button>
      <button className="next-slide" onClick={nextImg}><BsChevronRight className="chev-right" /></button>
      <button className="prev-slide" onClick={prevImg}><BsChevronLeft className="chev-left" /></button>

      <div className="slider-container">
        <Image imageUrl={allImgs[currentIndex].url} imageName={allImgs[currentIndex].name} class='slider-image' />
      </div>
    </div>
  );
}

export default Slider;
