import React from 'react';
import Image from './Image';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ListFiles = () => {
  const dataArr = useSelector((state) => state.categoryData);
  const storedArray = dataArr.allImgs;
  const data = dataArr.allData;

  function renderImages(imgs) {
    console.log(imgs);
    const renderedImages = [];
    let portraitCount = 0;
    let portraitImages = [];
    var portraitLinkClass;
    imgs.forEach((img, index) => {
      if (img.orientation === 'landscape') {
        renderedImages.push(
          <div class="landscape-container">
            <Link
              className='landscape-link'
              to='/slider'
              key={index}
              state={{ name: img.name, array: storedArray }}
            >
              <Image imageUrl={img.url} imageName={img.name} class='landscape-image' />
            </Link>
          </div>
        );
      } else if (img.orientation === 'portrait') {
        const nextIsPortrait = index < imgs.length - 1 && imgs[index + 1].orientation === 'portrait';
        const thirdIsPortrait = index < imgs.length - 2 && imgs[index + 2].orientation === 'portrait';
        portraitCount++;
        
        if(portraitCount === 1) {
          if (thirdIsPortrait) {
            portraitLinkClass = 'portrait-link3';
          } else if (nextIsPortrait) {
            portraitLinkClass = 'portrait-link2';
          } else {
            portraitLinkClass = 'portrait-link';
          }
        }
        
        portraitImages.push(
          <Link
            className={portraitLinkClass}
            to='/slider'
            key={index}
            state={{ name: img.name, array: storedArray }}
          >
            <Image imageUrl={img.url} imageName={img.name} class='portrait-image' />
          </Link>
        );
        var portraitClass;
        if (portraitCount === 3) {
          portraitClass = 'portrait-container3';
          renderedImages.push(
            <div className={portraitClass} key={index}>
              {portraitImages}
            </div>
          );
          portraitImages = [];
          portraitCount = 0;
        } else if (!nextIsPortrait) {
          portraitClass = portraitCount === 2 ? 'portrait-container2' : 'portrait-container';
          renderedImages.push(
            <div className={portraitClass} key={index}>
              {portraitImages}
            </div>
          );
          portraitImages = [];
          portraitCount = 0;
        }
      } 
    });

    return renderedImages;
  }

  return (
    <div className='main-container'>
      {data.map((value, key) => {
        return (
          <div key={key}>
            <h2 className='category-title'>{value.name}</h2>
            <div className='category-container'>
              {renderImages(value.imgs)}
            </div>
          </div>
        )
      })}
    </div >
  );
}

export default ListFiles;